<template>
  <div id="game-credit" class="p-2">
    <div class="container-fluid d-flex justify-content-between align-items-center mb-3">
      <div>
        <span class="text-secondary">เครดิตหลัก</span>
        <h3 class="mb-0 text-primary">{{ accBalance | amountFormat(2, '0.00') }}</h3>
      </div>
      <div>
        <span class="text-secondary">เครดิตเกม</span>
        <h3 class="mb-0 text-warning">{{ gameBalance | amountFormat(2, '0.00') }}</h3>
      </div>
      <div>
        <button @click="deposit" class="btn btn-success mx-1">เติมเครดิตเกม</button>
        <button @click="withdraw" class="btn btn-danger mx-1">ถอนเครดิตเกม</button>
      </div>
    </div>

    <table class="game-credit-history table table-sm table-warning table-bordered table-hover">
      <thead>
        <tr>
          <th width="180">วันที่ - เวลา</th>
          <th>รายละเอียด</th>
          <th width="150">จำนวน</th>
          <th width="150">คงเหลือ</th>
          <th width="200">หมายเหตุ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item._id">
          <td class="text-center">{{ item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss") }}</td>
          <td>{{ item.description }}</td>
          <td class="text-right" :class="[{'text-success': item.amounts.amount > 0}, {'text-danger': item.amounts.amount < 0}]">{{ item.amounts.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-right text-primary">{{ item.amounts.after | amountFormat(2, '0.00') }}</td>
          <td class="text-center">{{ item.remarks }}</td>
        </tr>
      </tbody>
    </table>

    <DepositGameCreditModal :is-show="isShowDepositModal" @close="modalClosed" />
    <WithdrawGameCreditModal :is-show="isShowWithdrawModal" @close="modalClosed" />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import DepositGameCreditModal from './components/DepositGameCreditModal'
import WithdrawGameCreditModal from './components/WithdrawGameCreditModal'

export default {
  name: 'GameCredit',
  components: {
    DepositGameCreditModal,
    WithdrawGameCreditModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      isShowDepositModal: false,
      isShowWithdrawModal: false
    }
  },
  computed: {
    accBalance() {
      return this.$store.state.account.balance
    },
    gameBalance() {
      return this.$store.state.gameAccount.balance
    },
    items() {
      return this.data || []
    }
  },
  methods: {
    deposit() {
      this.isShowDepositModal = true
    },
    withdraw() {
      this.isShowWithdrawModal = true
    },
    modalClosed(needReload) {
      this.isShowDepositModal = false
      this.isShowWithdrawModal = false

      if(needReload) {
        this.loadData()
        this.$store.dispatch('reloadBalance')
        this.$store.dispatch('reloadGameCredit')
      }
    },
    loadData() {
      this.isLoading = true
      GameService.getCredits()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss" scoped>
#game-credit {
  .game-credit-history {
    margin-bottom: 0;

    thead {
      tr {
        th {
          font-size: 90%;
          // font-weight: normal;
          text-align: center;
        }
      }
    }

    tbody {
      background-color: #FFFFFF;

      tr {
        td {
          font-size: 85%;
        }
      }
    }
  }
}
</style>
