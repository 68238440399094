import { render, staticRenderFns } from "./GameCredit.vue?vue&type=template&id=43fa9378&scoped=true&"
import script from "./GameCredit.vue?vue&type=script&lang=js&"
export * from "./GameCredit.vue?vue&type=script&lang=js&"
import style0 from "./GameCredit.vue?vue&type=style&index=0&id=43fa9378&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43fa9378",
  null
  
)

export default component.exports