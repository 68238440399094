<template>
  <b-modal
    ref="game-withdraw-modal"
    hide-footer
    no-close-on-backdrop
    title="ถอนเครดิตเกม"
    @show="setData"
    @hidden="resetData"
    class="position-relative"
    modal-class="game-withdraw-modal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="d-flex justify-content-around align-items-center alert alert-danger">
        <div>
          <span class="text-secondary">เครดิตหลัก</span>
          <h4 class="text-primary mb-0">{{ accBalance | amountFormat(2, '0.00') }}</h4>
        </div>
        <i class="fas fa-arrow-left fa-2x text-danger"></i>
        <div>
          <span class="text-secondary">เครดิตเกม</span>
          <h4 class="text-warning mb-0">{{ credit | amountFormat(2, '0.00') }}</h4>
        </div>
      </div>

      <div class="mt-3">
        <b-form-group
          label="จำนวนเครดิต"
        >
          <b-form-input
            v-model="input.amount"
            type="number"
            :number="true"
            size="lg"
            required
            placeholder="จำนวนเครดิตที่ต้องการถอน"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="หมายเหตุ"
        >
          <b-form-input
            v-model="input.note"
            type="text"
            size="lg"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="danger" :disabled="isLoading">{{isLoading ? 'กำลังถอนเครดิต...' : 'ถอนเครดิต'}}</b-button>
      </div>
    </form>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'

import cryptoRandomString from 'crypto-random-string'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'WithdrawGameCreditModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: {
        uniqueCode: null,
        amount: '',
        note: ''
      },
      isLoading: false,
      needReload: false
    }
  },
  computed: {
    accBalance() {
      return this.$store.state.account.balance
    },
    credit() {
      return this.$store.state.gameAccount?.balance || 0
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.needReload = false
      this.isLoading = false
      this.input = {
        uniqueCode: cryptoRandomString({length: 12, type: 'alphanumeric'}),
        amount: '',
        note: ''
      }
    },
    resetData() {},
    showModal() {
      this.$refs['game-withdraw-modal'].show()
    },
    hideModal() {
      this.$refs['game-withdraw-modal'].hide()
    },
    handleSubmit() {
      if(this.input.amount < 1 || this.input.amount > this.credit) {
        Swal.fire({
          text: 'จำนวนเครดิตไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return
      }

      this.isLoading = true
      GameService.withdraw(this.input)
      .then((response)=>{
        if(response.success) {
          this.$store.dispatch('reloadBalance')
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'ถอนเครดิตเสร็จเรียบร้อย',
          })
        }else{
          throw response
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ถอนเครดิตไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.input.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
.game-withdraw-modal {
  .modal-content {
    border: 1px solid #dc3545;

    .modal-header {
      background-color: #dc3545;
      padding: 0.5rem 1rem;
      color: #FFFFFF;
    }
  }
}
</style>
